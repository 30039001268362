<template>
  <div class="page-wrapper">
    <transition name="fade">
      <div class="page-loading" v-if="pageLoading">
        <Loading></Loading>
        <span class="loading-text">加载中</span>
      </div>
    </transition>
    <div class="page-container" id="scroller" ref="scrollerRef">
      <div class="page-content">
        <div class="main-info-wrapper">
          <h4 class="order-status">
            {{ currentStatus.desc ? currentStatus.desc : "未知定单状态" }}
          </h4>
          <h5 class="order-price">
            {{ orderDetail.amount | currency }}
          </h5>
          <div class="main-info-card-wrapper">
            <div class="main-info-card">
              <h4 class="info-row order-name">
                {{ orderDetail.salesNo ? orderDetail.salesNo : "未知" }}
              </h4>
              <h4 class="info-row company-info">
                {{ orderDetail.clientName ? orderDetail.clientName : "未知" }}
                {{ orderDetail.clientId ? orderDetail.clientId : "" }}
              </h4>
              <p class="info-row">
                工程：{{
                  orderDetail.engineeringCode
                    ? orderDetail.engineeringCode
                    : ""
                }}&nbsp;{{
                  orderDetail.engineeringName
                    ? orderDetail.engineeringName
                    : "暂无"
                }}
              </p>
              <p class="info-row">
                开发商：{{
                  orderDetail.developerName ? orderDetail.developerName : "暂无"
                }}
              </p>
              <p class="info-row">
                定单日期： {{ orderDetail.createdAt | date }}
              </p>
              <p class="info-row">
                交货日期：{{
                  orderDetail.deliveryDate ? orderDetail.deliveryDate : "暂无"
                }}
              </p>
              <p class="info-row">
                送货地址：{{
                  orderDetail.deliveryAddress
                    ? orderDetail.deliveryAddress
                    : "暂无"
                }}
              </p>
              <span class="order-type">{{ orderDetail.orderType }}</span>
            </div>
          </div>
        </div>
        <div class="logistics-wrapper" v-if="logisticsMessage.length > 0">
          <h6 class="page-section-title">物流动态</h6>
          <div v-if="messageContent.length > 0" class="logistics-message">
            <!-- <p>{{messageContent[0].createdAt | date_time}}</p> -->
            <p>{{ messageContent[0].content }}</p>
          </div>
          <header>
            <h5
              class="logistics-no"
              @click="navToLogisticsDetail(logisticsMessage[0].id)"
            >
              {{ logisticsMessage[0].no }}
            </h5>
            <span class="more-logistics" @click="navToLogisticsList"
              >查看更多<svgIcon iconClass="arrow"></svgIcon></span
            >
          </header>
          <div class="logistics-content">
            <span class="content-item"
              >司机：{{ logisticsMessage[0].driverName }}</span
            >
            <span class="content-item"
              >电话：{{ logisticsMessage[0].driverPhoneNo }}</span
            >
            <span class="content-item"
              >车牌：{{ logisticsMessage[0].carNo }}</span
            >
          </div>
        </div>
        <div class="product-list-wrapper">
          <h5 class="page-section-title">产品</h5>
          <div class="product-conut-wrapper">
            <div class="count-item box-shadow">
              <span class="item-title">金额合计</span>
              <span class="item-value">{{
                summary.amountWithTax | currency
              }}</span>
            </div>
            <div class="count-item box-shadow">
              <span class="item-title">片数合计</span>
              <span class="item-value">{{ summary.tiles }} 片</span>
            </div>
            <div class="count-item box-shadow">
              <span class="item-title">平方数合计</span>
              <span class="item-value"> {{ summary.squareMeters }} m²</span>
            </div>
          </div>

          <div class="product-list-container" id="productListContainer">
            <div class="scroller-list">
              <div
                class="product-item box-shadow"
                v-for="item in products"
                :key="item.id"
              >
                <header class="code">
                  <span>{{ item.oldProductId }}</span>
                  <!-- <span>{{ item.specification }}</span> -->
                </header>
                <div class="item-content">
                  <div class="item-row">系统产品编码：{{ item.productId }}</div>
                  <div class="item-row">产品描述：{{ item.productName }}</div>
                  <div class="item-row">等级：{{ item.gradeName }}</div>
                  <div class="item-row">包装方式：{{ item.packingMethod }}</div>
                  <div class="item-row">
                    长：{{ item.length }}，宽：{{ item.width }}，高：{{
                      item.height
                    }}
                  </div>
                  <div class="item-row">
                    加工类型：{{ item.processingTypeName }}
                  </div>
                  <div class="item-row">
                    片数：{{ item.bricks }} 片，箱数：{{
                      item.cartons
                    }}
                    箱，片/箱：{{ item.bricksPerCarton }}
                  </div>
                  <div class="item-row">平方数：{{ item.squareMeters }} m²</div>
                  <div class="item-row">
                    含税单价：{{ item.priceWithTax | currency }}
                  </div>
                  <div class="item-row">
                    平方单价：{{ item.pricePerSquareMeter | currency }}
                  </div>
                  <div class="item-row">
                    含税总价：{{ item.amountWithTax | currency }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-tips">&lt; &nbsp;滑动查看 &nbsp;&gt;</div>
        </div>
        <div class="contact-card">
          <div class="card-content">
            <h4 class="contact-name">{{ orderDetail.merchandiserName }}</h4>
            <p class="contact-info">{{ orderDetail.merchandiserPhoneNo }}</p>
            <p class="contact-info">{{ orderDetail.merchandiserEmail }}</p>
          </div>
          <div class="card-footer">
            <span> 销售员：{{ orderDetail.ownerSalesmanName }}</span>
            <span> 业务员：{{ orderDetail.salesmanName }}</span>
          </div>
          <span class="bage">客服经理</span>
        </div>
        <div class="harvest-card">
          <header>收货信息</header>
          <div class="content">
            <span
              >收货人：{{
                orderDetail.consigneeName ? orderDetail.consigneeName : "暂无"
              }}</span
            >
            <span
              >联系方式：{{
                orderDetail.consigneePhoneNo
                  ? orderDetail.consigneePhoneNo
                  : "暂无"
              }}</span
            >
            <span>交货日期：{{ orderDetail.deliveryDate | date }}</span>
            <span
              >运输方式：{{
                orderDetail.transportMode ? orderDetail.transportMode : "暂无"
              }}</span
            >
          </div>
        </div>
        <div class="order-message-wrapper" v-if="messageContent.length > 0">
          <h5 class="page-section-title">定单动态</h5>
          <div class="order-message-content">
            <template v-for="message in messageContent">
              <div class="order-message-item" :key="message.id">
                <h6 class="message-content">
                  {{ message.content }}
                </h6>
                <p class="message-desc">
                  {{ message.createdByUserName }}&nbsp;|&nbsp;
                  {{ message.createdAt | date_time }}
                </p>
              </div>
            </template>
          </div>
          <transition name="fade">
            <div class="message-loading" v-show="messageLoading">
              正在获取...
            </div>
          </transition>
        </div>
      </div>
      <transition name="fade">
        <div class="refresh-loading" v-if="pageLoading">正在刷新...</div>
      </transition>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import BScroll from "better-scroll";
import {
  GetSaleOrderPubDetail,
  GetSaleOrderPubCurrentStatus,
  GetSaleOrderPubSummary,
  GetSaleOrdersPubProductList,
  GetSaleOrdersPubMessage,
} from "./api";
import { GetLogisticsList } from "@/views/logistics/api";
export default {
  components: { Loading },
  data() {
    return {
      pageLoading: true,
      pageScroller: null,
      productScroller: null,
      currentId: null,
      orderDetail: {},
      currentStatus: {},
      summary: {},
      products: [],
      messageContent: [],
      messageLoading: false,
      messagePage: 0,
      messageSize: 99,
      messageTotal: 0,
      isMessageLast: false,
      logisticsMessage: [],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.currentId = this.$route.params.id;
    }
    this.pageLoading = true;
    this.initDetail();
  },
  methods: {
    initScrollBar() {
      this.$nextTick(() => {
        this.pageScroller = new BScroll("#scroller", {
          // ...
          pullDownRefresh: true,
          wheel: false,
          scrollbar: true,
          click: true,
          bounce: {
            bottom: false,
          },
          // and so on
        });
        this.pageScroller.on("scrollEnd", () => {
          this.loadMessage();
        });
        this.pageScroller.on("pullingDown", () => {
          // console.log("下拉刷新");
          this.pageLoading = true;
          this.initDetail();
        });
        this.productScroller = new BScroll("#productListContainer", {
          scrollX: true,
          // and so on
        });
      });
    },
    initDetail() {
      GetSaleOrderPubDetail(this.currentId)
        .then((res) => {
          this.orderDetail = res.data;
          // console.log("请求的数据结果", this.orderDetail);
          return GetSaleOrderPubCurrentStatus(this.currentId);
        })
        .then((statusResult) => {
          this.currentStatus = statusResult.data;

          // console.log("定单状态", this.currentStatus);
          return GetSaleOrderPubSummary(this.currentId);
        })
        .then((summaryResult) => {
          this.summary = summaryResult.data;
          // console.log("定单总数", this.summary);
          return GetSaleOrdersPubProductList(this.currentId, {
            page: 0,
            size: 999,
          });
        })
        .then((productResult) => {
          this.products = productResult.data.content;

          // console.log("定单产品", this.products);
          return GetSaleOrdersPubMessage({
            page: this.messagePage,
            size: this.messageSize,
            objId: [this.currentId],
            objTypeId: "SAP_SALES_ORDER",
          });
        })
        .then((messageResult) => {
          let { content, last, totalElements } = messageResult.data;
          this.messageContent = content;
          this.isMessageLast = last;
          this.messageTotal = totalElements;
          return GetLogisticsList({
            sapSalesOrderId: this.currentId,
            page: 0,
            size: 999,
          });
        })
        .then((logisticsResult) => {
          this.logisticsMessage = logisticsResult.data.content;
          // console.log("物流信息", this.logisticsMessage);
        })
        .finally(() => {
          this.initScrollBar();
          if (this.pageScroller !== null) {
            this.pageScroller.finishPullDown();
          }
          this.pageLoading = false;
        });
    },

    loadMessage() {
      if (!this.isMessageLast) {
        this.messageLoading = true;
        this.pageScroller.refresh();
        this.messagePage++;
        this.getOrderMessage();
      }
    },

    getOrderMessage() {
      GetSaleOrdersPubMessage({
        page: this.messagePage,
        size: this.messageSize,
        objId: [this.currentId],
        objTypeId: "SAP_SALES_ORDER",
      })
        .then((messageResult) => {
          let { content, last, totalElements } = messageResult.data;
          if (this.messagePage == 0) {
            this.messageContent = content;
          } else {
            this.messageContent = [...this.messageContent, ...content];
          }
          this.isMessageLast = last;
          this.messageTotal = totalElements;
        })
        .catch((err) => {
          console.log("数据请求异常", err);
        })
        .finally(() => {
          this.messageLoading = false;
          this.pageScroller.refresh();
        });
    },
    navToLogisticsDetail(id) {
      this.$router.push({
        name: "logisticsDetail",
        params: { id: id, orderId: this.currentId },
        query:{
          back: true
        },
      });
    },
    navToLogisticsList() {
      console.log('this.orderDetail',this.orderDetail)
      this.$router.push({
        name: "logisticsByOrder",
        params: { orderId: this.currentId },
        query: {
          name: this.orderDetail.salesNo,
        },
      });
    },
  },
  beforeDestroy() {
    this.scroller.destroy();
    this.productScroller.destroy();
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  font-size: 16px;
  color: #333;
  height: 100%;
  position: relative;
  .page-container {
    height: 100%;
    overflow: hidden;
    position: relative;
    .refresh-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #000;
    }
  }
  .box-shadow {
    box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
  }
  .page-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 66;
    .loading-text {
      margin-top: 20px;
      font-size: 14px;
      color: #989898;
    }
  }
  .page-section-title {
    margin: 15px auto;
    font-size: 17px;
    text-align: center;
  }
}
.main-info-wrapper {
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  background-color: #14b5f5;
  overflow: hidden;
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }
  .order-status {
    margin: 40px auto 0 auto;
    color: #fff;
    font-size: 22px;
  }
  .order-price {
    font-size: 18px;
    margin: 20px auto;
  }
  .main-info-card-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    position: relative;
    .main-info-card {
      box-sizing: border-box;
      margin: 0 auto;
      padding: 15px;
      border-radius: 10px;
      color: #000;
      background-color: #fff;
      box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
      position: relative;
      z-index: 1;
      text-align: left;
      font-size: 13px;
      .info-row {
        color: #6d6c6c;
        margin-bottom: 6px;
      }
      .order-name {
        color: #000;
        font-size: 17px;
        margin-bottom: 15px;
      }
      .order-type {
        position: absolute;
        bottom: 10px;
        right: 20px;
        color: #f39881;
        font-size: 12px;
      }
    }
    &::after {
      content: "";
      width: 100%;
      top: 20%;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #fff;
      position: absolute;
    }
  }
}
.logistics-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: left;
  .logistics-title {
    margin: 15px auto;
    font-size: 17px;
    text-align: center;
  }
  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    & .logistics-no {
      font-size: 15px;
      color: #333;
      font-weight: 550;
      margin: 0;
      color: #14b5f5;
    }
    & .more-logistics {
      font-size: 13px;
      color: #989798;
    }
  }
  .logistics-content {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .content-item {
      text-align: left;
      font-size: 12px;
      color: #333333;
    }
  }
  .logistics-message {
    padding-bottom: 15px;
    box-sizing: border-box;
    // padding-left: 15px;
    font-size: 14px;
    color: #e6961e;
    P {
      margin: 5px;
    }
  }
}
.product-list-wrapper {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
  .product-title {
    margin: 10px auto;
    font-size: 17px;
  }
  .product-conut-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 0 5px;
    display: flex;
    margin-bottom: 10px;
    .count-item {
      flex: 1;
      margin: 0 5px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 4px;
      .item-title {
        font-size: 13px;
        padding-bottom: 5px;
      }
      .item-value {
        font-size: 13px;
      }
    }
  }
  .product-list-container {
    box-sizing: border-box;
    width: 100%;
    .scroller-list {
      padding: 0 0 0 10px;
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      .product-item {
        flex: 300px 0 0;
        width: 300px;
        margin-right: 10px;
        text-align: left;
        padding: 10px;
        font-size: 14px;
      }
      .code {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
      }
      .item-row {
        margin-bottom: 4px;
      }
    }
  }
  .scroll-tips {
    font-size: 13px;
    color: #989898;
    text-align: center;
    margin-top: 10px;
  }
}
.contact-card {
  box-sizing: border-box;
  margin: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
  background-color: #fff;
  text-align: left;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .card-content {
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    font-size: 14px;
    .contact-name {
      margin: 0;
      font-size: 16px;
      color: #000;
      font-weight: 800;
      margin-bottom: 15px;
    }
    .contact-info {
      margin: 6px auto;
    }
  }
  .card-footer {
    box-sizing: border-box;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    background-color: #f0f0f0;
  }
  .bage {
    box-sizing: border-box;
    padding: 4px 10px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    background-color: #f39881;
    border-bottom-left-radius: 10px;
    color: #fff;
  }
}
.harvest-card {
  box-sizing: border-box;
  margin: 20px 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
  background-color: #fff;
  text-align: left;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  header {
    font-size: 15px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 0 4px;
  }
  .content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    span {
      flex: 50% 0 0;
      box-sizing: border-box;
      padding: 4px;
      color: #8a8a8a;
    }
  }
}
.order-message-wrapper {
  box-sizing: border-box;
  border-bottom: 1px dashed #f2f2f2;
  & .title {
    font-size: 17px;
    color: #000;
    margin: 15px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
  }
  .order-message-content {
    margin: 5px 20px;
    // box-shadow: 0 0 10px rgba($color: #333333, $alpha: 0.1);
    // border-radius: 10px;
    position: relative;
    .order-message-item {
      box-sizing: border-box;
      margin-bottom: 10px;
      padding: 10px 0;
      text-align: left;
      font-size: 14px;
      color: #b1afaf;
      margin-bottom: 10px;
      border-bottom: 1px dashed #dddcdc;
      &:last-child {
        border-bottom: none;
      }
      .message-content {
        font-size: 13px;
        margin: 0 0 5px 0;
        font-weight: 400;
      }
      .message-desc {
        margin: 0;
        font-size: 12px;
        margin-top: 10px;
      }
      // &.height-light {
      //   font-size: 16px;
      //   color: #fc892b;
      //   text-shadow: 2px 2px 6px #f7d6bb;
      // }
      &:first-child {
        .message-content {
          font-size: 15px;
          color: #fc892b;
          text-shadow: 2px 2px 2px #f7d6bb;
        }
        .message-desc {
          color: #fc892b;
        }
      }
    }
  }
  .message-loading {
    width: 100%;
    font-size: 13px;
    color: #989898;
    text-align: center;
    padding-bottom: 15px;
  }
}

</style>